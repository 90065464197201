import "../styles/globals.scss";
import type { AppProps } from "next/app";
import Head from "next/head";
import { useEffect, useState } from "react";
import "tailwindcss/tailwind.css";
import Script from "next/script";
import { ThemeProvider } from "../context/ThemeContext";

export default function App({ Component, pageProps }: AppProps) {
  const [showChild, setShowChild] = useState(false);
  useEffect(() => {
    setShowChild(true);
  }, []);

  if (!showChild) {
    return null;
  }
  return (
    <ThemeProvider>
      <Head>
        <title>Eagle码力一哥</title>
        <meta
          name="description"
          content="Eagle 是一个帮助开发人员分析代码库和项目结构的 AI Agent。它能够深入阅读和理解程序代码文件，识别关键功能，并提供详细的解释和改进建议。
"
        />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,maximum-scale=1,user-scalable=no,viewport-fit=coverwidth=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover,minimum-scale=1,maximum-scale=1,user-scalable=no"
        />
        {/* <link rel="icon" href="/favicon.ico" /> */}
      </Head>
      <Script
        src="https://hm.baidu.com/hm.js?c7e45dee40f40a3fecaf41b63c34d68d"
        strategy="afterInteractive" // 在页面交互后加载脚本
        onLoad={() => {
          // 确保 _hmt 数组已定义
          var _hmt: any = _hmt || [];
          console.log("Baidu Tongji script has been loaded");
        }}
      />
      <Component {...pageProps} />
    </ThemeProvider>
  );
}
